class Validator {
  constructor(rules) {
    Object.keys(rules).forEach(field => {
      this.rules[field] = rules[field].split("|");
    });
  }

  regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
  rules = {};

  validate(value, field) {
    let error = null
    this.rules[field] && this.rules[field].forEach(rule => {
      switch(rule){
        case 'required':
          // Fix-it: 異なるルールでバリデーションエラーが発生した場合、上書きしてしまう。
          // 複数のエラーをどうするか？
          if(!value) error = rule;
          break;
        case 'email':
          if(value && !this.regexp.test(value)) error = rule;
          break;
        default:
          break;
      }
    });
    return error;
  }
}

export default Validator;
