import React, { Component, useState } from "react";
import { observer } from 'mobx-react';
import { Link } from 'gatsby';
import Button from "src/components/LinkedButton"
import Mainsection from "src/components/section/main"
import InqueryFormStore from "../../stores/InqueryFormStore"
import Spacer from "src/components/spacer"
import Wrap from "src/components/wrap"

const store = new InqueryFormStore();

class InqueryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      thanks: false,
      sending: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (!store.validate()) return;
    try {
      this.setState({ error: "", sending: true });
      await store.createInquery();
      this.setState({ thanks: true, sending: false });
    }
    catch (error) {
      this.setState({ error: error, sending: false })
      window.scrollTo(0, 0)
    }
  }

  handleChange = (e) => {
    store.onFieldChange(e.target.name, e.target.value);
  };

  render() {
    return (
      <Mainsection title="お問い合わせフォーム" >
        <div className="container">
          <Spacer isHalf />
          {this.state.thanks && <ThanksMessage />}
          {this.state.error && <ErrorMessage />}
          {!this.state.thanks && <FormBody store={store} sending={this.state.sending} onSubmit={this.handleSubmit} onChange={this.handleChange} />}
        </div>
      </Mainsection>
    );
  }
}

const FormBody = observer((props) => {

  const [agree, setAgree] = useState(false)

  const handleChangeAgree = (e) => {
    setAgree(!agree)
  };

  const errorClass = (error) => (
    error ? "is-danger" : ""
  )

  const renderError = (error) => {
    const Component = ({ children }) => <p className="help has-text-weight-bold is-danger">{children}</p>
    switch (error) {
      case "required":
        return <Component>必須項目です。</Component>;
      case "email":
        return <Component>メールアドレス形式が正しくありません。</Component>;
      default:
        break;
    }
    return null;
  }

  const { form } = props.store;
  return (
    <div>
      <form onSubmit={props.onSubmit}>
        <div className="field">
          <label className="label" htmlFor="input_name">お名前<RequiredLabel /></label>
          <div className="control">
            <input className={`input ${errorClass(form.errors.name)}`} type="text" id="input_name" name="name" onChange={props.onChange} value={form.values.name} />
            {renderError(form.errors.name)}
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="input_company">会社名</label>
          <div className="control">
            <input className="input" type="text" id="input_company" name="company" onChange={props.onChange} value={form.values.company} />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="input_email">メールアドレス<RequiredLabel /></label>
          <div className="control">
            <input className={`input ${errorClass(form.errors.email)}`} id="input_email" name="email" onChange={props.onChange} value={form.values.email} />
            {renderError(form.errors.email)}
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="input_content">お問い合わせ内容<RequiredLabel /></label>
          <div className="control">
            <textarea className={`textarea ${errorClass(form.errors.content)}`} id="input_content" name="content" onChange={props.onChange} value={form.values.content} />
            {renderError(form.errors.content)}
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="input_content">個人情報取扱について<RequiredLabel /></label>
          <div>
            <label className="checkbox">
              <input type="checkbox" checked={agree} onChange={handleChangeAgree} /> <span className="has-text-weight-bold has-text-white"><a href="https://info.natade-coco.com/policies/privacy/" target="_black">プライバシーポリシー</a>に同意します</span>
            </label>
          </div>
        </div>

        <div className="field is-grouped is-grouped-centered">
          <div className="control">
            <button type="submit" className="button is-link is-info has-text-weight-bold" disabled={!agree || !form.isValid || props.sending}>{props.sending ? "送信中" : "送信する"}</button>
          </div>
          <div className="control">
            <Link to="/contact"><button className="button is-outlined is-light has-text-weight-bold">戻る</button></Link>
          </div>
        </div>
      </form>
    </div>
  );
});

const Message = ({ title, color, colorHex, icon, children }) => (
  <div className="box is-shadowless" style={{ border: `solid 4px ${colorHex}`, paddingLeft: ".5rem", paddingRight: ".5rem" }}>
    <div className="content has-text-centered">
      <span className={`icon is-large has-text-${color}`}>
        <i className={`${icon} fa-3x`}></i>
      </span>
      <h2 className={`title is-size-3 is-size-4-mobile has-text-${color}`} style={{ marginTop: ".75em" }}>{title}</h2>
      <span className={`is-size-7-mobile has-text-weight-bold has-text-${color}`}>
        {children}
      </span>
    </div>
  </div>
)

const ErrorMessage = () => (
  <Message title="申し訳ございません" icon="fas fa-sad-tear" color="danger" colorHex="#df003c">
    <Wrap>
      <span>送信中にエラーが発生しました。</span><br />
      <span>大変申し訳ありませんが、</span><span>時間をおいて再度送信ください</span>
    </Wrap>
  </Message>
)

const ThanksMessage = () => (
  <Message title="送信が完了しました" icon="fas fa-check-circle" color="primary" colorHex="#09b4c6">
    <Wrap>
      <span>お問い合わせありがとうございました。</span><br />
      <span>担当者よりご連絡</span><span>差し上げますので、</span><span>しばらくお待ちください。</span>
    </Wrap>
    <Spacer isSmall />
    <div className="control has-text-centered">
      <Button to="/contact" title="戻る" />
    </div>
  </Message>
)

const RequiredLabel = () => <span className="tag is-danger" style={{ marginLeft: ".5rem" }}>必須</span>

export default InqueryForm;
