import { Directus } from "@directus/sdk"

class Client {
  constructor() {
    this.sdk = new Directus("https://cms.natade-coco.com/");
  }

  createInquery(data) {
    return this.sdk.items("inquery").createOne(data);
  }
}

export default new Client();
