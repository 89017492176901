import { makeObservable, action, observable } from 'mobx';
import client from "../services/client"
import Validator from "../util/validator";

class InqueryFormStore {
  constructor() {
    makeObservable(this, {
      form: observable,
      setFieldValue: action,
      setFieldError: action,
    })
    this.validation = new Validator(this.rules);
  }

  form = {
    values: {
      name: "",
      company: "",
      email: "",
      content: "",
    },
    errors: {
    },
    isValid: false,
  }

  rules = {
    name: 'required',
    email: 'required|email',
    content: 'required',
  }

  errors = {
    name: 'required',
    email: 'required',
    content: 'required',
  }

  onFieldChange = (field, value) => {
    this.setFieldValue(field, value);
    this.setFieldError(field, this.validateField(field));
  }

  setFieldValue = (field, value) => {
    this.form.values[field] = value;
  }

  setFieldError = (field, error) => {
    this.errors[field] = error;
    this.form.errors[field] = error;
    this.form.isValid = Object.keys(this.errors).every(field => !this.errors[field]);
  }

  validate = () => {
    Object.keys(this.form.values).forEach(field => {
      this.setFieldError(field, this.validateField(field));
    });
    return this.form.isValid;
  }

  validateField = (field) => {
    return this.validation.validate(this.form.values[field], field);
  }

  clearValues = () => {
    Object.keys(this.form.values).forEach(field => {
      this.setFieldValue(field, "");
      this.setFieldError(field, null);
    });
  }

  createInquery = () => {
    return client.createInquery(this.form.values).then(() => {
      this.clearValues();
    });
  }
}

export default InqueryFormStore;
