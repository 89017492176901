import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import InqueryForm from "src/components/contact/InqueryForm"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <InqueryForm />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
